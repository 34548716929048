export const removeWidows = (text, howManyLastWords = 3) => {
  if (howManyLastWords < 2) return text;

  // Build regex for the number of target last words.
  let i = 1; let
    baseRegEx = '[\\w*\\’*\\`*\\-*]*\\.?)[ ]?$';
    // eslint-disable-next-line
  while (i < howManyLastWords) { baseRegEx = `[\\w*\\’*\\\`*\\-*]*\\W${baseRegEx}`; i++; }
  baseRegEx = `(${baseRegEx}`;
  const antiWidowRegEx = new RegExp(baseRegEx);

  // Check if text even has that many last words.
  if (!antiWidowRegEx.test(text)) return text;

  // Ok, words found; Replace the last spaces with no-breaking-spaces.
  const lastWords = antiWidowRegEx.exec(text)[1];
  return `${text.replace(lastWords, lastWords.replace(/[ ]/, ' <nobr>'))}</nobr>`;
};
const helper = {
  removeWidows,
};

export default helper;
