import React from 'react';
import classNames from 'classnames';

import textTypes, { textDefaultProps } from '../../types/textTypes';

import { removeWidows } from '../../utils/helpers';

import './Text.scss';

class Text extends React.PureComponent {
  static propTypes = textTypes;

  static defaultProps = textDefaultProps;

  render() {
    const {
      className,
      color,
      hasSplit,
      onClick,
      size,
      tag,
      value,
      widows,
      ...props
    } = this.props;
    if (!value) {
      return null;
    }
    const Tag = tag;
    const textProps = {
      className: classNames(
        'Text',
        `text--${size}${color ? `--${color}` : ''}`,
        className,
      ),
      onClick,
      ...props,
    };
    if (!hasSplit) {
      return (
        <Tag
          {...textProps}
          dangerouslySetInnerHTML={{ __html: removeWidows(value, widows) }}
        />
      );
    }
    const values = value
      .split('')
      .map((val, idx) => ({ val, key: `Text${val}${idx}` }));
    return (
      <React.Fragment>
        {values.map(({ val, key }, index) => (
          <Tag
            key={key}
            {...textProps}
            onClick={onClick ? event => onClick(event, { val, index }) : null}
          >
            {val === ' ' ? '\u00A0' : val}
          </Tag>
        ))}
      </React.Fragment>
    );
  }
}

export default Text;
